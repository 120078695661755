/* .App {
  text-align: center;
} */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&family=Kanit&family=Mulish&family=Noto+Sans:wght@300&family=Nunito:wght@300;400&family=Poppins:ital,wght@0,400;1,200&family=Tilt+Neon&display=swap");

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  background-color: #e6eeee !important;
  font-size: 15px;
  /* color: white; */
}
.jsoneditor-search {
  display: none !important;
}
.jsoneditor-values .jsoneditor-tree .jsoneditor-readonly {
  display: none !important;
}
.jsoneditor-values .jsoneditor-tree .jsoneditor-object {
  display: none !important;
}

.css-7h0pg9-MuiTypography-root {
  font-size: unset !important;
}

/* loading starts */
.loader {
  background: transparent;
  width: 100%;
  height: 100%;
  line-height: 50px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0.2em;
  z-index: 9999999;
}
.loader span {
  position: absolute;
  width: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
}
.loader span::before,
.loader span::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #01a102;
  position: absolute;
  animation: load 0.7s infinite alternate ease-in-out;
}
.loader span::before {
  top: 0;
}
.loader span::after {
  bottom: 0;
}
/* loading ends */

/* sidebarcolor and width work */

/* .css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  background-color: hsl(224deg 49% 31%) !important;
} */
/* .css-1packh4-MuiList-root {
  background-color: hsl(224deg 49% 31%) !important ;
  color: white !important;
} */
/* .css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: hsl(224deg 49% 31%) !important;
} */
.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: black !important;
  color: #f4a504 !important;
}
/* div.jsoneditor-field {
  color: red !important;
} */
div.jsoneditor-value.jsoneditor-string {
  color: hsl(224deg 49% 31%) !important;
}
div.jsoneditor-value.jsoneditor-string:hover {
  background-color: none !important;
}
/* .css-10hburv-MuiTypography-root {
  font-size: 14px !important;
} */

div.jsoneditor-field[contenteditable="true"]:hover {
  background-color: white !important;
  border-color: white !important;
}

/* .css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  width: 332px !important;
} */
/* .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  border-bottom: 1px solid #808080bd !important;
} */

.css-10hburv-MuiTypography-root {
  /* width: 140px !important; */
  white-space: break-spaces;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #6778a985 !important;
}
/* .css-pwcg7p-MuiCollapse-root:hover {
  background-color: #6778a985 !important;
} */
/* .css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: #4f566b !important;
} */

/* desiner */
.API-list-item span {
  font-size: 13px !important;
}

/* custom scrollbar */
.sidebar-drawer .MuiPaper-elevation::-webkit-scrollbar {
  width: 4px;
}

.sidebar-drawer .MuiPaper-elevation::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar-drawer .MuiPaper-elevation::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
/* custom scrollbar */

.collapse-list:hover {
  background: none;
}
.collapse-list
  .MuiCollapse-wrapper
  .MuiCollapse-wrapperInner
  .MuiList-root:hover {
  background-color: #6778a985;
}
.btn {
  border: none !important;
  box-shadow: none;
}
.orange-btn {
  vertical-align: middle !important;
}
.orange-btn:hover {
  background: #000 none repeat scroll 0 0 !important;
  z-index: -1;
}
.dark-card {
  background-color: #0c314e !important;
}
.dark-card .MuiCardContent-root {
  padding-bottom: 16px !important;
}
.API-link-section .MuiGrid-root p {
  color: #fff;
}
.API-link-section .MuiGrid-grid-xs-3 {
  display: flex;
  justify-content: end;
  align-items: center;
}
.yellow-btn:hover {
  background-color: #ffb606 !important;
}
.API-input-card {
  background-color: #f7fafc !important;
}
.API-input-card .MuiCardContent-root .MuiTabs-root {
  background-color: #e3e8ee !important;
}
.API-input-card .MuiTabs-root {
  background-color: #e3e8ee !important;
}
.mock-API-card .MuiPaper-root {
  background-color: transparent;
}
.mock-API-response {
  /* background-color: rgba(13, 50, 79, 0.3) !important; */
  background-color: #b6c1ca !important;
}
.mock-API-response .MuiCardContent-root .MuiGrid-root {
  background-color: #0c314e !important;
}
.mock-API-response .MuiCardContent-root .MuiGrid-root p {
  color: #fff;
}
.mock-API-response .MuiCardContent-root .MuiGrid-container {
  padding: 1rem;
}
.mock-API-response table.jsoneditor-tree {
  /* background-color: rgba(13, 50, 79, 0.3); */
  background-color: #b6c1ca;
}
.data-type-card .MuiGrid-root a.MuiLink-root {
  color: hsl(224deg 49% 31%);
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
}
.data-type-card .MuiGrid-root span.data-type {
  font-size: 13px;
  color: #f3712c;
  margin-left: 0.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.data-type-card .MuiGrid-spacing-xs-2 {
  margin-top: 1rem;
}
.data-type-card p {
  font-size: 14px;
  line-height: 24px;
}
.body-content-section {
  padding-right: 1rem;
}
.active-menu {
  color: #f3712c;
  font-weight: 500;
}

.ace-jsoneditor span.ace_string {
  pointer-events: none;
}
.green-status {
  background-color: green;
  padding: 3px 8px;
  font-weight: 700;
}
.red-status {
  background-color: red;
  padding: 3px 8px;
  font-weight: 700;
}
.ace-jsoneditor .ace_gutter,
.ace_scroller {
  background-color: #b6c1ca !important;
}
.jsoneditor-statusbar {
  background-color: #b6c1ca !important;
  border-top: none !important;
  color: #000 !important;
}
textarea.ace_text-input {
  /* display: none; */
}
.ace_scrollbar.ace_scrollbar-v .MuiPaper-elevation::-webkit-scrollbar {
  width: 4px;
}
.ace_scrollbar.ace_scrollbar-v .MuiPaper-elevation::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.ace_scrollbar.ace_scrollbar-v .MuiPaper-elevation::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.parameterBackground .ace_scroller .ace_content {
  background-color: #f7fafc;
}
/* .ace_line_group .ace_line .ace_variable {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
} */
.jsoneditor-statusbar {
  display: none;
}

.btn1:hover {
  background-color: #f3712c !important;
}

::-webkit-scrollbar {
  width: 0px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  color: white;
  /* overflow: auto; */
}
table thead {
  /* border-bottom: 1.5px solid black; */
  background-color: lightgrey;
}
table {
  /* background-color: rgb(41, 75, 117); */
  color: black;
}
td,
th {
  padding: 18px;
}
/* .table thead tr th {
  font-size: 15px;
  padding: 18px 0;
} */
tbody tr {
  border-bottom: solid 1px lightgrey;
}
.pagination-text {
  /* background-color: rgb(41, 75, 117); */
  color: black !important;
}
.wallettkn {
  display: flex;
  justify-content: end;
  gap: 30px;
}
.DashDateRangePicker {
  position: absolute !important;
  /* right: 4% !important;
  justify-content: left !important;
  z-index: 100 !important; */
}
.datebut {
  border: 1px solid #f3712c;
  background-color: white;
  border-radius: 5px;
  margin-right: 500px;
  padding-top: 7px;
  padding-bottom: 15px;
}

.cardhead {
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.respon {
  margin-left: 250px;
}

@media (min-width: 320px) and (max-width: 1100px) {
  .Home_DashDateRangePicker__SnVpw {
    width: 110%;
  }
}

/*Background Img*/
.back-img {
  position: relative;
  width: 100%;
  margin: auto;
}

.logincard {
  padding: 1.5rem;
  margin-top: 5rem;
}
.logtxt {
  width: 42%;
}
.logbutton {
  border-radius: 10px !important;
}

.wave {
  width: 20px;
  height: 40px;
  background: linear-gradient(45deg, rgb(240, 240, 23), rgb(211, 209, 69));
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.sign-in-text h2 {
  /* color: white; */
  font-weight: bold;
  font-family: "Anonymous Pro", monospace;
  text-align: center;
  font-size: 35px;
  margin-top: 0;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 430px;
  }
}

.DashDateRangePicker {
  position: absolute !important;
  right: 4% !important;
  justify-content: left !important;
  z-index: 100 !important;
}
@media (min-width: 320px) and (max-width: 990px) {
  .css-1vism8w-MuiGrid-root {
    flex-wrap: wrap !important;
  }
}
.modalbutton {
  display: flex !important;
  justify-content: center !important;
}
.accept-btn {
  color: white !important;
  width: 20% !important;
  padding: 8px !important;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.accept-btn:hover {
  background-color: #1976d2 !important;
  color: rgb(255, 255, 255) !important;
}

.reject-btn {
  background-color: "error" !important;
  color: white !important;
  width: 20% !important;
  padding: 8px !important;
  font-weight: bold;
}

.reject-btn:hover {
  background-color: "error" !important;
  color: rgb(250, 248, 248) !important;
}
.fee_box {
  width: 30%;
  border-radius: 5px;
  background-color: #1b2634;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
  color: white;
}

/* new loading */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.wave {
  width: 15px;
  height: 80px;
  background: linear-gradient(45deg, rgb(136, 255, 0), rgb(211, 209, 69));
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.down_arrow {
  cursor: pointer;
  font-size: 25px !important;
  width: 1%;
  color: white;
  background-color: lightgray;
  margin-bottom: 2px;
  border-radius: 5px;
}
.down_arrow:hover {
  color: lightgray;
  background-color: white;
}
.sorting_div {
  display: grid;
  margin-left: 15px;
}
.down_arrow {
  cursor: pointer;
  font-size: 25px !important;
  width: 1%;
  color: white;
  /* background-color: lightgray; */
  margin-bottom: 2px;
  border-radius: 5px;
}
.down_arrow:hover {
  /* color: lightgray; */
  /* background-color: white; */
}
.sorting_div {
  display: grid;
  margin-left: 15px;
}
